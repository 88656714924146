import { faSquareCheck, faSquareMinus } from "@fortawesome/free-solid-svg-icons"
import { faSquare } from "@fortawesome/free-regular-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface RSCheckboxIconProps {
  max: number
  cur: number
}

const RSCheckboxIcon: React.FC<RSCheckboxIconProps> = ({ max, cur }) => {
  return (
    <FontAwesomeIcon
      className="w-4 h-4 text-sky-600"
      icon={cur === 0 ? faSquare : cur === max ? faSquareCheck : faSquareMinus}
    />
  )
}

export default RSCheckboxIcon
