import { RuleOption } from "../standards/Misra"

interface RuleOptionFieldProps {
  optionName: string
  option: RuleOption<any>
  readOnly: boolean
  onChange: (newValue: string | number | boolean) => void
}

const OPTION_NAMES = new Map<string, string>([
  ["standard", "标准选项"],
  ["externalIdentifierSignificance", "外部标识符有效字符长度"],
  ["caseSensitive", "是否区分大小写"],
  ["maxLoop", "最大循环"],
]) // TODO: use react-i18next

const RuleOptionField: React.FC<RuleOptionFieldProps> = ({
  optionName,
  option,
  readOnly,
  onChange,
}) => {
  return (
    <div className="flex flex-row items-center space-x-4">
      <label className="m-8 my-2 w-48">
        {OPTION_NAMES.get(optionName) ?? optionName}
      </label>
      {optionName === "standard" &&
        (readOnly ? (
          <input
            className={
              "w-48 h-8 border-2 rounded border-transparent text-center"
            }
            type="text"
            readOnly
            value={option.currentValue ?? option.defaultValue}
          />
        ) : (
          <select
            className="w-48 text-center"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="c89">c89</option>
            <option value="c90">c90</option>
            <option value="c99">c99</option>
            <option value="c11">c11</option>
          </select>
        ))}
      {optionName === "externalIdentifierSignificance" && (
        <input
          className={
            "w-48 h-8 border-2 rounded text-center" +
            (readOnly ? " border-transparent" : "")
          }
          type={readOnly ? "text" : "number"}
          readOnly={readOnly}
          value={option.currentValue ?? option.defaultValue}
          min="1"
          max="63"
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {optionName === "caseSensitive" &&
        (readOnly ? (
          <input
            className={
              "w-48 h-8 border-2 rounded border-transparent text-center"
            }
            type="text"
            readOnly
            value={
              (option.currentValue ?? option.defaultValue) === "true"
                ? "是"
                : "否"
            }
          />
        ) : (
          <select
            className="w-48 text-center"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value === "true")}
          >
            <option value="true">是</option>
            <option value="false">否</option>
          </select>
        ))}
      {optionName === "maxLoop" && (
        <input
          className={
            "w-48 h-8 border-2 rounded text-center" +
            (readOnly ? " border-transparent" : "")
          }
          type={readOnly ? "text" : "number"}
          min="0"
          readOnly={readOnly}
          value={option.currentValue ?? option.defaultValue}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  )
}

export default RuleOptionField
