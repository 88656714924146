import RSCheckboxIcon from "../components/RSCheckboxIcon"
import { CodingStandard, CodingStandardChapter } from "../standards/Common"
import { MisraRule } from "../standards/Misra"
import ChapterMenu from "./ChapterMenu"

interface StandardListProps {
  standard: CodingStandard
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void
}

const StandardList: React.FC<StandardListProps> = ({
  standard,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
}) => {
  return (
    <>
      <p className="ml-8 my-4 font-semibold text-xl">添加到检查项</p>
      <div className="flex flex-row items-center ml-8 my-4 space-x-4">
        <label className="items-center font-normal text-lg">
          <input
            className="hidden"
            type="checkbox"
            checked={
              standard.chapters.reduce(
                // Accumulate all selected rules in all chapters
                (accum, chapter) =>
                  accum + chapter.rules.filter((rule) => rule.selected).length,
                0
              ) > 0
            }
            onChange={(e) => onSelectStandard(standard, e.target.checked)}
          />
          <RSCheckboxIcon
            max={standard.chapters.reduce(
              (accum, chapter) => accum + chapter.rules.length,
              0
            )}
            cur={standard.chapters.reduce(
              (accum, chapter) =>
                accum + chapter.rules.filter((rule) => rule.selected).length,
              0
            )}
          />
          <span className="ml-4">{standard.name}</span>
        </label>
      </div>
      <div className="ml-4">
        {standard.chapters.map((chapter) => (
          <ChapterMenu
            key={chapter.name}
            chapter={chapter}
            onSelectChapter={onSelectChapter}
            onSelectRule={onSelectRule}
          />
        ))}
      </div>
    </>
  )
}

export default StandardList
