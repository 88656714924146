import React from "react"

import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { CodingStandardChapter } from "../standards/Common"
import { MisraRule } from "../standards/Misra"
import RuleItem from "../components/RuleItem"
import RSCheckboxIcon from "../components/RSCheckboxIcon"

interface ChapterMenuProps {
  chapter: CodingStandardChapter
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
}

const ChapterMenu: React.FC<ChapterMenuProps> = (props) => {
  const [collapsed, setCollapsed] = React.useState(false)

  return (
    <div className={collapsed ? "" : "mb-4"}>
      <div className="flex flex-row items-center">
        <label className="mb-4 mx-4">
          <input
            className="hidden "
            type="checkbox"
            checked={
              props.chapter.rules.filter((rule) => rule.selected).length > 0
            }
            onChange={(e) =>
              props.onSelectChapter(props.chapter, e.target.checked)
            }
          />
          <RSCheckboxIcon
            max={props.chapter.rules.length}
            cur={props.chapter.rules.filter((rule) => rule.selected).length}
          />
        </label>
        <div
          className={
            "h-12 flex items-center justify-between border-solid border-2 mb-4 w-full"
          }
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        >
          <label className="inline-block w-full text-sky-600 font-bold">
            <FontAwesomeIcon
              className="ml-4 mr-4 w-4"
              icon={collapsed ? faAngleRight : faAngleDown}
            />
            {props.chapter.name}
          </label>
        </div>
      </div>
      {!collapsed && (
        <div className="flex flex-row">
          <div className="ml-1 w-12"></div>
          <table className="border-collapse w-full">
            <tbody>
              {props.chapter.rules.map((rule) => (
                <RuleItem
                  key={rule.id}
                  ruleName={`${rule.ident} ${rule.subject}`}
                  selected={rule.selected}
                  onChange={(e) => props.onSelectRule(rule, e.target.checked)}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ChapterMenu
