import RSButton from "../components/RSButton"

interface FooterProps {
  children: JSX.Element
  onClickNextStep: () => void
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <div className="py-6 px-4 flex items-center justify-between ">
      {props.children}
      <div className="flex mt-4 space-x-6 mt-0">
        <RSButton
          className="w-24"
          label="下一步"
          onClick={props.onClickNextStep}
        />
      </div>
    </div>
  )
}

export default Footer
