interface RSButtonProps {
  className?: string
  label?: JSX.Element | string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const RSButton: React.FC<RSButtonProps> = (props) => {
  return (
    <button
      className={`bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded ${props.className}`}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  )
}

export default RSButton
