import { CodingStandard } from "./Common"

export type Catagory = "Mandatory" | "Required" | "Advisory"
export type Decidability = "Decidable" | "Undecidable"

export interface MisraRule {
  id: string
  ident: string
  category: Catagory
  decidability: Decidability
  scope: "Single Translation Unit" | "System"
  appliesToC90: boolean
  appliesToC99: boolean
  docsId: string
  subject: string
  selected: boolean
  visible: boolean
  options: MisraRuleOptions
}

interface MisraRuleOptions {
  caseSensitive?: RuleOption<boolean>
  standard?: RuleOption<"C90" | "C99">
  externalIdentifierSignificance?: RuleOption<number>
  maxLoop?: RuleOption<number>
}

export interface RuleOption<Type> {
  key: string
  defaultValue: Type | null
  previousValue: Type | null
  currentValue: Type | null
  affectedBy?: string
}

export const encodeToCheckRules = (standard: CodingStandard) => {
  return standard.chapters
    .flatMap((c) =>
      c.rules
        .filter((r) => r.selected)
        .map((r) => {
          const options = (
            Object.entries(r.options) as [string, RuleOption<any>][]
          ).reduce(
            (obj, [_, option]) => ({
              ...obj,
              [option.key]: option.currentValue ?? option.defaultValue,
            }),
            {}
          )
          return `${r.id} ${JSON.stringify(options)}`
        })
    )
    .join("\n")
}

export const decodeFromCheckRules = (data: string) => {
  const standard = require("./MisraStandard.json") as CodingStandard
  const allRules = standard.chapters.reduce(
    (arr, c) => arr.concat(c.rules),
    [] as MisraRule[]
  )
  try {
    data.split(/\r?\n/).forEach((line) => {
      if (line.length === 0) return
      // For `misra_2012/rule_1_1 {"opt":"val"}`
      const ruleId =
        line.indexOf(" ") > 0 ? line.substring(0, line.indexOf(" ")) : line
      const jsonOption =
        line.indexOf(" ") > 0
          ? line.substring(line.indexOf(" ") + 1).trim()
          : ""
      const rule = allRules.find((r) => r.id === ruleId)
      if (rule) rule.selected = true
      else throw new Error(`invalid ruleId: ${ruleId}`)
      if (rule && jsonOption.length > 0) {
        const optionObj: any = JSON.parse(jsonOption)
        ;(
          Object.entries(optionObj) as [
            string, // Option Key
            any // Option Value
          ][]
        ).forEach(([key, value]) => {
          ;(
            Object.entries(rule.options) as [
              string, // Name
              RuleOption<any>
            ][]
          ).forEach(([_, option]) => {
            if (option.key === key) {
              option.currentValue = value
              option.previousValue = value
            }
          })
        })
      }
    })
  } catch (error) {
    console.error(error)
    return [null, error]
  }
  return [standard, null]
}
