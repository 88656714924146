import { Dispatch, SetStateAction } from "react"

import { CodingStandard } from "../standards/Common"
import Navbar, { Stage } from "../containers/Navbar"
import EditorLayout from "../layouts/EditorLayout"
import Footer from "../containers/Footer"
import RSButton from "../components/RSButton"
import RuleOptionsEditor from "../containers/RuleOptionsEditor"

interface PreviewPageProps {
  standard: CodingStandard
  updateStandard: Dispatch<SetStateAction<CodingStandard>>
  updateStage: Dispatch<SetStateAction<Stage>>
}

const PreviewPage: React.FC<PreviewPageProps> = ({
  standard,
  updateStandard,
  updateStage,
}) => {
  return (
    <EditorLayout
      navbar={
        <Navbar
          stage="preview"
          updateStage={updateStage}
        />
      }
      sidebar={
        <div className="text-right text-2xl font-semibold m-8">预览</div>
      }
      editor={
        <RuleOptionsEditor
          readOnly
          standard={standard}
          updateStandard={updateStandard}
        />
      }
      footer={
        <Footer onClickNextStep={() => updateStage("download")}>
          <div className="flex mt-4 space-x-6 mt-0">
            <RSButton
              className="bg-white hover:bg-white text-black"
              label="上一步"
              onClick={() => updateStage("edit settings")}
            />
          </div>
        </Footer>
      }
    />
  )
}

export default PreviewPage
