import { Dispatch, SetStateAction } from "react"

import { Catagory, Decidability, MisraRule } from "../standards/Misra"
import { CodingStandard, CodingStandardChapter } from "../standards/Common"
import Navbar, { Stage } from "../containers/Navbar"
import EditorLayout from "../layouts/EditorLayout"
import Footer from "../containers/Footer"
import Sidebar from "../containers/Sidebar"
import StandardList from "../containers/StandardList"

interface RuleSelectionPageProps {
  standard: CodingStandard
  updateStandard: Dispatch<SetStateAction<CodingStandard>>
  updateStage: Dispatch<SetStateAction<Stage>>
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void
}

const RuleSelectionPage: React.FC<RuleSelectionPageProps> = ({
  standard,
  updateStandard,
  updateStage,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
}) => {
  const onSelectedByCategory = (category: Catagory, selected: boolean) => {
    standard.chapters.forEach((chapter) =>
      chapter.rules.forEach((rule) => {
        if (rule.category === category) rule.selected = selected
      })
    )
    updateStandard({ ...standard })
  }

  const onSelectedByDecidability = (
    decidability: Decidability,
    selected: boolean
  ) => {
    standard.chapters.forEach((chapter) =>
      chapter.rules.forEach((rule) => {
        if (rule.decidability === decidability) rule.selected = selected
      })
    )
    updateStandard({ ...standard })
  }

  const selectedRulesNumber = standard.chapters.reduce(
    (accum, chapter) =>
      accum + chapter.rules.filter((rule) => rule.selected).length,
    0
  )

  const selectedAnyAdvisory =
    standard.chapters.reduce(
      (accum, chapter) =>
        accum +
        chapter.rules.filter(
          (rule) => rule.selected && rule.category === "Advisory"
        ).length,
      0
    ) > 0

  const selectedAnyRequired =
    standard.chapters.reduce(
      (accum, chapter) =>
        accum +
        chapter.rules.filter(
          (rule) => rule.selected && rule.category === "Required"
        ).length,
      0
    ) > 0

  const selectedAllMandatory =
    standard.chapters.reduce(
      (accum, chapter) =>
        accum +
        chapter.rules.filter(
          (rule) => rule.category === "Mandatory" && !rule.selected
        ).length,
      0
    ) === 0

  const showHintForAdvisory = selectedAnyAdvisory && !selectedAllMandatory
  const showHintForRequired = selectedAnyRequired && !selectedAllMandatory
  // Key: two-length bit with "showHintForRequired" as higher and "showHintForAdvisory" as lower
  const stagesOrder: Map<number, JSX.Element> = new Map([
    [0, <></>],
    [
      1,
      <p className="text-red-500">
        如未选必选类别“强制”，仅选择“建议”可能会导致您的代码不合规。
      </p>,
    ],
    [
      2,
      <p className="text-amber-600">
        如未选必选类别“强制”，仅选择“要求”可能会导致您的代码不合规。
      </p>,
    ],
    [
      3,
      <p className="text-red-500">
        如未选必选类别“强制”，仅选择“要求”和“建议”可能会导致您的代码不合规。
      </p>,
    ],
  ])

  return (
    <EditorLayout
      navbar={
        <Navbar
          stage="select rules"
          updateStage={updateStage}
        />
      }
      sidebar={
        <Sidebar
          standard={standard}
          onSelectedByCategory={onSelectedByCategory}
          onSelectedByDecidability={onSelectedByDecidability}
        />
      }
      editor={
        <StandardList
          standard={standard}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      }
      footer={
        <Footer onClickNextStep={() => updateStage("edit settings")}>
          <span className="text-sm">
            <p>已选择 {selectedRulesNumber} 条规则</p>
            {stagesOrder.get(
              (showHintForAdvisory ? 1 : 0) + (showHintForRequired ? 2 : 0)
            )}
          </span>
        </Footer>
      }
    />
  )
}

export default RuleSelectionPage
