import { Dispatch, SetStateAction, useState, useRef } from "react"
import {
  faFileLines,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { CodingStandard } from "../standards/Common"
import { decodeFromCheckRules } from "../standards/Misra"
import { Stage } from "../containers/Navbar"
import RSButton from "../components/RSButton"

interface InitialPageProps {
  updateStage: Dispatch<SetStateAction<Stage>>
  updateStandard: Dispatch<SetStateAction<CodingStandard>>
}

const InitialPage: React.FC<InitialPageProps> = ({
  updateStage,
  updateStandard,
}) => {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    const { files } = e.dataTransfer
    files && setSelectedFiles(files)
  }

  const drag = useRef(null)
  const [dragging, setDragging] = useState<boolean>(false)

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target !== drag.current) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target === drag.current) {
      setDragging(false)
    }
  }

  const inputFile = useRef<HTMLInputElement>(null)

  const [selectedFiles, setSelectedFiles] = useState<FileList>()
  const [showError, setShowError] = useState<boolean>(false)

  const parseSelectedFiles = () => {
    if (selectedFiles) {
      selectedFiles[0].text().then((data) => {
        const [standard, error] = decodeFromCheckRules(data) as [
          CodingStandard,
          Error
        ]
        if (error === null) {
          standard && updateStandard(standard)
          updateStage("select rules")
        } else {
          setShowError(true)
        }
      })
    }
  }

  return (
    <>
      <div className="absolute container mx-auto pointer-events-none">
        <img
          className="my-16 px-16 h-12"
          src="/logo.svg"
          alt="logo"
        />
      </div>
      <div
        className="flex justify-center items-center h-screen flex-col bg-scroll bg-center bg-cover"
        style={{ backgroundImage: "url(/background.svg)" }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {dragging && (
          <div
            className="absolute w-full h-full bg-[rgba(0,0,0,0.8)] flex justify-center items-center text-white text-xl font-semibold"
            ref={drag}
          >
            将文件拖拽至此区域
          </div>
        )}
        {selectedFiles ? (
          <div className="gap-4 flex flex-col justify-center items-center">
            <div className="w-48 h-48 bg-[#F8F8F8] flex justify-center items-center">
              <FontAwesomeIcon
                className="ml-4 mr-4 text-9xl opacity-50"
                icon={faFileLines}
              />
            </div>
            <p className="text-lg"> {selectedFiles[0].name} </p>
            <RSButton
              className="w-64 text-xl"
              label="确认上传"
              onClick={parseSelectedFiles}
            />
            <p className="text-lg">或拖拽文件至此区域替换</p>
            <RSButton
              className="w-32 bg-sky-100 hover:bg-sky-200 text-sky-600"
              label="返回"
              onClick={() => setSelectedFiles(undefined)}
            />
          </div>
        ) : (
          <div className="gap-4 flex flex-col justify-center items-center">
            <RSButton
              className="w-64"
              label="直接开始"
              onClick={() => updateStage("select rules")}
            />
            <p>或</p>
            <input
              type="file"
              className="hidden"
              ref={inputFile}
              onChange={(e) => {
                const files = e.target.files
                files && setSelectedFiles(files)
              }}
            />
            <RSButton
              className="w-64"
              label="点击上传文件"
              onClick={() => inputFile.current?.click()}
            />
            <p>或拖拽文件至此区域开始</p>
          </div>
        )}
        {showError && (
          <div className="absolute right-0 bottom-0 h-16 w-80 rounded bg-[#FEF2F2] m-8 flex justify-center items-center">
            <FontAwesomeIcon
              className="w-4 h-4 m-4 text-[#F87171]"
              icon={faXmarkCircle}
            />
            <span className="text-[#991B1B] grow">上传文件格式有误</span>
            <FontAwesomeIcon
              className="w-4 h-4 m-4 text-[#991B1B]"
              icon={faXmark}
              onClick={() => setShowError(false)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default InitialPage
