import { Catagory, Decidability, MisraRule } from "../standards/Misra"
import { CodingStandard } from "../standards/Common"
import RSCheckboxIcon from "../components/RSCheckboxIcon"

interface SidebarProps {
  standard: CodingStandard
  onSelectedByCategory: (category: Catagory, selected: boolean) => void
  onSelectedByDecidability: (
    decidability: Decidability,
    selected: boolean
  ) => void
}

const Sidebar: React.FC<SidebarProps> = ({
  standard,
  onSelectedByCategory,
  onSelectedByDecidability,
}) => {
  const countRulesWithFilter = (filter: (rule: MisraRule) => boolean) => {
    return standard.chapters.reduce(
      (accum, chapter) =>
        accum + chapter.rules.filter((rule) => filter(rule)).length,
      0
    )
  }
  const categories: Map<Catagory, string> = new Map([
    ["Mandatory", "强制"],
    ["Required", "要求"],
    ["Advisory", "建议"],
  ])
  const decidabilities: Map<Decidability, string> = new Map([
    ["Decidable", "可判定"],
    ["Undecidable", "不可判定"],
  ])
  return (
    <div className="p-8 flex flex-col space-y-4">
      <div className="text-base font-bold">类别</div>
      <div className="bg-neutral-100 flex flex-col p-2 space-y-1">
        {Array.from(categories).map(([category, name]) => {
          const selectedRulesNumber = countRulesWithFilter(
            (rule) => rule.selected && rule.category === category
          )
          return (
            <label
              key={category}
              className="flex items-center"
            >
              <input
                className="hidden"
                type="checkbox"
                checked={selectedRulesNumber > 0}
                onChange={(e) =>
                  onSelectedByCategory(category, e.target.checked)
                }
              />
              <RSCheckboxIcon
                max={countRulesWithFilter((rule) => rule.category === category)}
                cur={selectedRulesNumber}
              />
              <span className="pl-2 text-sm">
                {name} ({selectedRulesNumber})
              </span>
            </label>
          )
        })}
      </div>
      <div className="text-base font-bold">可判定性</div>
      <div className="bg-neutral-100 flex flex-col p-2 space-y-1">
        {Array.from(decidabilities).map(([decidability, name]) => {
          const selectedRulesNumber = countRulesWithFilter(
            (rule) => rule.selected && rule.decidability === decidability
          )
          return (
            <label
              key={decidability}
              className="flex items-center"
            >
              <input
                className="hidden"
                type="checkbox"
                checked={selectedRulesNumber > 0}
                onChange={(e) =>
                  onSelectedByDecidability(decidability, e.target.checked)
                }
              />
              <RSCheckboxIcon
                max={countRulesWithFilter(
                  (rule) => rule.decidability === decidability
                )}
                cur={selectedRulesNumber}
              />
              <span className="pl-2 text-sm">
                {name} ({selectedRulesNumber})
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
