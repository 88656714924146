import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

import { CodingStandard, CodingStandardChapter } from "../standards/Common"
import { MisraRule } from "../standards/Misra"
import RSCheckboxIcon from "../components/RSCheckboxIcon"

interface RuleSetProps {
  chapter: CodingStandardChapter
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
}

const RuleSet: React.FC<RuleSetProps> = ({
  chapter,
  onSelectRule,
  onSelectChapter,
}) => {
  const ruleSetName =
    chapter.name === "Dir" ? "Dir" : chapter.rules[0].ident.split(".")[0] // Use `Rule x` for `Rule x.y`
  const selectedRules = chapter.rules.filter((r) => r.selected).length
  const [collapsed, setCollapsed] = React.useState(selectedRules === 0)
  return (
    <div>
      <div className="flex flex-row hover:bg-neutral-100 items-center gap-2 -ml-2 pl-2">
        <label>
          <input
            className="hidden"
            type="checkbox"
            checked={selectedRules > 0}
            onChange={(e) => {
              onSelectChapter(chapter, e.target.checked)
            }}
          />
          <RSCheckboxIcon
            max={chapter.rules.length}
            cur={selectedRules}
          />
        </label>
        <div
          className="group h-8 flex items-center justify-between w-full"
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        >
          <label className="inline-block w-full text-grey-600 truncate flex items-center justify-between">
            {ruleSetName} ({selectedRules})
            <FontAwesomeIcon
              className="w-4 mr-4 text-white group-hover:text-black"
              icon={faAngleDown}
            />
          </label>
        </div>
      </div>
      {!collapsed &&
        chapter.rules.map((rule) => (
          <div
            key={rule.id}
            className="ml-2 pl-2 h-8 text-[#102A43] hover:bg-sky-100 hover:text-sky-600 flex flex-row items-center justify-between"
          >
            <label className="w-full inline-block truncate">
              <input
                className="hidden"
                type="checkbox"
                checked={rule.selected}
                onChange={(e) => onSelectRule(rule, e.target.checked)}
              />
              <span className="mr-2">
                <RSCheckboxIcon
                  max={1}
                  cur={rule.selected ? 1 : 0}
                />
              </span>
              {rule.ident} {rule.subject}
            </label>
          </div>
        ))}
    </div>
  )
}

interface RuleListProps {
  standard: CodingStandard
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void
}

const RuleList: React.FC<RuleListProps> = ({
  standard,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
}) => {
  const selectedRules = standard.chapters.reduce(
    (accum, chapter) =>
      accum + chapter.rules.filter((rule) => rule.selected).length,
    0
  )
  return (
    <div className="m-6">
      <label className="text-grey-600 flex items-center gap-2">
        <input
          className="hidden"
          type="checkbox"
          checked={selectedRules > 0}
          onChange={(e) => onSelectStandard(standard, e.target.checked)}
        />
        <RSCheckboxIcon
          max={standard.chapters.reduce(
            (accum, chapter) => accum + chapter.rules.length,
            0
          )}
          cur={selectedRules}
        />
        {standard.name} ({selectedRules})
      </label>
      <div className="ml-4">
        {standard.chapters.map((chapter) => (
          <RuleSet
            key={chapter.name}
            onSelectRule={onSelectRule}
            onSelectChapter={onSelectChapter}
            chapter={chapter}
          />
        ))}
      </div>
    </div>
  )
}

export default RuleList
