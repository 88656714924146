import { Dispatch, SetStateAction } from "react"

import { CodingStandardChapter, CodingStandard } from "../standards/Common"
import { MisraRule } from "../standards/Misra"
import Navbar, { Stage } from "../containers/Navbar"
import EditorLayout from "../layouts/EditorLayout"
import Footer from "../containers/Footer"
import RSButton from "../components/RSButton"
import RuleOptionsEditor from "../containers/RuleOptionsEditor"
import RuleList from "../containers/RuleList"

interface SettingsEditorProps {
  standard: CodingStandard
  updateStandard: Dispatch<SetStateAction<CodingStandard>>
  updateStage: Dispatch<SetStateAction<Stage>>
  onSelectRule: (rule: MisraRule, selected: boolean) => void
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void
}

const SettingsEditorPage: React.FC<SettingsEditorProps> = ({
  standard,
  updateStandard,
  updateStage,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
}) => {
  return (
    <EditorLayout
      navbar={
        <Navbar
          stage="edit settings"
          updateStage={updateStage}
        />
      }
      sidebar={
        <RuleList
          standard={standard}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      }
      editor={
        <RuleOptionsEditor
          standard={standard}
          updateStandard={updateStandard}
        />
      }
      footer={
        <Footer onClickNextStep={() => updateStage("preview")}>
          <div className="flex mt-4 space-x-6 mt-0">
            <RSButton
              className="bg-white hover:bg-white text-black"
              label="上一步"
              onClick={() => updateStage("select rules")}
            />
          </div>
        </Footer>
      }
    />
  )
}

export default SettingsEditorPage
