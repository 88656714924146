import Navbar from "../containers/Navbar"

interface EditorLayoutProps {
  navbar: JSX.Element
  sidebar: JSX.Element
  editor: JSX.Element
  footer: JSX.Element
}

const EditorLayout: React.FC<EditorLayoutProps> = ({
  navbar,
  sidebar,
  editor,
  footer,
}) => {
  return (
    <div className="flex flex-col h-screen divide-y">
      {navbar}
      <div className="flex flex-row overflow-y-auto h-screen divide-x">
        <div className="basis-1/4 overflow-y-auto">{sidebar}</div>
        <div className="basis-3/4 flex flex-col overflow-y-auto">
          <div className="flex-grow">{editor}</div>
        </div>
      </div>
      <div className="sticky static bottom-0 bg-white flex flex-row">
        <div className="basis-1/4"></div>
        <div className="basis-3/4">{footer}</div>
      </div>
    </div>
  )
}

export default EditorLayout
