import React from "react"

import { CodingStandard, CodingStandardChapter } from "./standards/Common"
import { MisraRule } from "./standards/Misra"
import DownloadPage from "./pages/DownloadPage"
import InitialPage from "./pages/InitialPage"
import Navbar, { Stage } from "./containers/Navbar"
import PreviewPage from "./pages/PreviewPage"
import RuleSelectionPage from "./pages/RuleSelectionPage"
import SettingsEditorPage from "./pages/SettingsEditorPage"

import "./App.css"

function App() {
  const [stage, setStage] = React.useState<Stage>("init")
  // Use mock value first, will be changed later.
  const [standard, setStandard] = React.useState<CodingStandard>(
    require("./standards/MisraStandard.json")
  )

  const onSelectRule = (rule: MisraRule, selected: boolean) => {
    rule.selected = selected
    setStandard({ ...standard })
  }

  const onSelectChapter = (
    chapter: CodingStandardChapter,
    selected: boolean
  ) => {
    chapter.rules.forEach((rule) => (rule.selected = selected))
    setStandard({ ...standard })
  }

  const onSelectStandard = (standard: CodingStandard, selected: boolean) => {
    standard.chapters.forEach((chapter) =>
      chapter.rules.forEach((rule) => (rule.selected = selected))
    )
    setStandard({ ...standard })
  }

  // TODO: use HashRouter to support "Go back one page"
  return (
    <div>
      {stage === "init" && (
        <InitialPage
          updateStandard={setStandard}
          updateStage={setStage}
        />
      )}
      {stage === "select rules" && (
        <RuleSelectionPage
          standard={standard}
          updateStandard={setStandard}
          updateStage={setStage}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      )}
      {stage === "edit settings" && (
        <SettingsEditorPage
          standard={standard}
          updateStandard={setStandard}
          updateStage={setStage}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      )}
      {stage === "preview" && (
        <PreviewPage
          standard={standard}
          updateStage={setStage}
          updateStandard={setStandard}
        />
      )}
      {stage === "download" && (
        <DownloadPage
          standard={standard}
          updateStage={setStage}
        />
      )}
    </div>
  )
}

export default App
