import React, { Dispatch, SetStateAction, useRef } from "react"

import { faClipboard } from "@fortawesome/free-regular-svg-icons"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { CodingStandard } from "../standards/Common"
import { encodeToCheckRules } from "../standards/Misra"
import { Stage } from "../containers/Navbar"
import RSButton from "../components/RSButton"

interface DownloadPageProps {
  standard: CodingStandard
  updateStage: Dispatch<SetStateAction<Stage>>
}

const DownloadPage: React.FC<DownloadPageProps> = ({
  standard,
  updateStage,
}) => {
  const checkRules = encodeToCheckRules(standard)
  const url = URL.createObjectURL(
    new Blob([checkRules], {
      type: "text/plain",
    })
  )

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const copyToClipboard = () => {
    var textField = document.createElement("textarea")
    textField.value = checkRules
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
    textareaRef?.current?.select()
  }

  return (
    <>
      <div className="absolute container mx-auto pointer-events-none">
        <img
          className="my-16 px-16 h-12"
          src="/logo.svg"
          alt="logo"
        />
      </div>
      <div
        className="flex flew row bg-scroll bg-center bg-cover"
        style={{ backgroundImage: "url(/background.svg)" }}
      >
        <div className="basis-1/4 flex flex-col gap-4 justify-end items-center mb-16">
          <RSButton
            className="w-64"
            label="返回首页"
            onClick={() => updateStage("init")}
          />
          <RSButton
            className="w-64"
            label="返回操作界面"
            onClick={() => updateStage("select rules")}
          />
        </div>
        <div className="flex justify-center items-center h-screen flex-col basis-1/2 gap-6">
          <p className="text-4xl">设定更改成功</p>
          <a
            href={url}
            download="check_rules"
            target="_blank"
            rel="noreferrer"
          >
            <RSButton
              className="w-64"
              label={
                <div className="">
                  <FontAwesomeIcon
                    className="w-4 h-4"
                    icon={faDownload}
                  />
                  点击下载文件
                </div>
              }
            />
          </a>
          <p className="text-xl ">或</p>
          <div className="flex justify-center items-center gap-4">
            <div
              className="w-12 h-12 bg-[#4E4E4E] flex justify-center items-center rounded hover:bg-neutral-500 active:bg-neutral-600"
              onClick={copyToClipboard}
            >
              <FontAwesomeIcon
                className="w-6 h-6 text-white"
                icon={faClipboard}
              />
            </div>
            <span className="text-xl">
              复制以下设定内容至"check_rules"文件内
            </span>
          </div>
          <textarea
            className="w-full bg-[#4E4E4E] text-white h-64 p-4"
            value={checkRules}
            readOnly
            ref={textareaRef}
          ></textarea>
        </div>
      </div>
    </>
  )
}

export default DownloadPage
