import { Dispatch, SetStateAction } from "react"
import RuleOptionField from "../components/RuleOptionField"

import { CodingStandard } from "../standards/Common"
import { RuleOption } from "../standards/Misra"

interface RuleOptionsEditorProps {
  readOnly?: boolean
  standard: CodingStandard
  updateStandard: Dispatch<SetStateAction<CodingStandard>>
}

const RuleOptionsEditor: React.FC<RuleOptionsEditorProps> = ({
  readOnly,
  standard,
  updateStandard,
}) => {
  const selectedRules = standard.chapters.flatMap((chapter) =>
    chapter.rules.filter((rule) => rule.selected)
  )
  return (
    <>
      {!readOnly && <p className="m-8 font-semibold text-xl">批量设定</p>}
      <div className="divide-y">
        {!readOnly && (
          <p className="m-8 mb-4 font-normal text-lg">{standard.name}</p>
        )}
        {selectedRules.map((rule) => (
          <div
            key={rule.id}
            className="my-4"
          >
            <p className="m-8 my-4 mb-2 font-semibold text-base">
              {rule.ident} {rule.subject}
            </p>
            {(
              Object.entries(rule.options) as [
                string,
                RuleOption<string | number | boolean>
              ][]
            ) // TODO: use different input type for different option type
              .map(([optionName, option]) => (
                <RuleOptionField
                  key={`${rule.id} ${optionName}`}
                  optionName={optionName}
                  option={option}
                  readOnly={readOnly ?? false}
                  onChange={(newValue) => {
                    standard.chapters.forEach((c) =>
                      c.rules.forEach((r) => {
                        if (r.selected) {
                          ;(
                            Object.entries(r.options) as [
                              string,
                              RuleOption<string | number | boolean>
                            ][]
                          ).forEach(([ruleOptionName, ruleOption]) => {
                            if (ruleOptionName === optionName) {
                              ruleOption.currentValue = newValue
                              ruleOption.affectedBy = rule.id
                            }
                          })
                        }
                      })
                    )
                    updateStandard({ ...standard })
                  }}
                />
              ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default RuleOptionsEditor
