import { ChangeEventHandler } from "react"
import RSCheckboxIcon from "./RSCheckboxIcon"

interface RuleItemProps {
  ruleName: string
  selected: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

const RuleItem: React.FC<RuleItemProps> = (props) => {
  // TODO: use theme.colors instead of hardcoded values
  return (
    <tr>
      <td className="border-solid border-2 border-[#E8EAED] h-12 justify-between text-[#102A43] hover:bg-sky-100 hover:text-sky-600">
        <label className="inline-block w-full h-full flex items-center">
          <input
            className="hidden"
            type="checkbox"
            checked={props.selected}
            onChange={props.onChange}
          />
          <span className="m-4">
            <RSCheckboxIcon
              max={1}
              cur={props.selected ? 1 : 0}
            />
          </span>
          {props.ruleName}
        </label>
      </td>
    </tr>
  )
}

export default RuleItem
