import { Dispatch, SetStateAction } from "react"

export type Stage =
  | "init"
  | "select rules"
  | "edit settings"
  | "preview"
  | "download"

interface NavbaProps {
  stage: Stage
  updateStage: Dispatch<SetStateAction<Stage>>
}

const Navbar: React.FC<NavbaProps> = (props) => {
  const stages: Map<Stage, string> = new Map([
    ["select rules", "添加检查项"],
    ["edit settings", "批量设定"],
    ["preview", "预览"],
  ])
  const stagesOrder: Map<Stage, number> = new Map([
    ["select rules", 1],
    ["edit settings", 2],
    ["preview", 3],
  ])
  return (
    <nav className="flex flex-wrap items-center justify-between p-6 bg-white-100 space-x-8">
      <img
        className="h-6"
        src="/logo.svg"
        alt="logo"
      />
      <div className="flex flex-grow items-center w-auto">
        {Array.from(stages).map(([stage, name], idx) => {
          const clickable =
            (stagesOrder.get(stage) ?? 0) < (stagesOrder.get(props.stage) ?? 0)
          return (
            <div key={stage}>
              {idx > 0 && <span className="text-gray-500">/</span>}
              <span
                className={
                  "ml-4 mr-4 text-sm " +
                  (props.stage === stage ? "text-[#2680C2]" : "text-gray-500") +
                  (clickable ? " hover:text-[#2680C2] hover:underline" : "")
                }
                onClick={() => {
                  if (clickable) {
                    props.updateStage(stage)
                  }
                }}
              >
                {name}
              </span>
            </div>
          )
        })}
      </div>
    </nav>
  )
}

export default Navbar
